@import "styles/variables.scss";

.game-page-header {
  align-items: center;
}

.game-page-panels {
  flex-direction: column;
  gap: 1rem;
}

.game-page-hero-grid {
  flex: auto;
}

@media (min-width: $breakpoint-md) {
  .game-page-panels {
    flex-direction: row;
  }
}

@media (min-width: $breakpoint-xs) {
  .game-page-header {
    align-items: stretch;
  }
}
