@import "styles/variables.scss";

.modal-content-dark {
  background-color: $page-background-color-dark;
  border: 1px solid rgba(255, 255, 255, 0.15);
}

.modal-content-dark .modal-header {
  border-bottom: 1px solid $card-bg-dark;
}

.modal-content-dark .modal-footer {
  border-top: 1px solid $card-bg-dark;
}

.modal-content-dark .modal-header .modal-title {
  color: white;
}
