@import "styles/variables.scss";

.update-alert {
  justify-content: center;
  white-space: pre-wrap;
  padding: 3px 5px 5px 5px;
  flex-wrap: wrap;
}
.update-alert-light {
  color: black;
  background-color: lighten($secondary-color-light, 20%);
}
.update-alert-dark {
  color: white;
  background-color: $secondary-color-dark;
}

.update-alert-refresh {
  color: $primary-color-dark;
}
.update-alert-refresh:hover {
  cursor: pointer;
  text-decoration: underline;
}
.update-alert-refresh-light {
  color: $primary-color-light;
}
.update-alert-refresh-dark {
  color: lighten($primary-color-dark, 20%);
}
