@import "styles/variables.scss";

.status-bar {
  min-height: 10rem;
}
.status-bar-no-icons {
  min-height: 8rem;
}

.status-bar-text {
  flex: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.status-bar-text-large {
  h3 {
    font-size: 3rem;
  }
}

.status-bar-text-fade {
  opacity: 0;
  -webkit-animation: statusTextFade 1s;
  -moz-animation: statusTextFade 1s;
  animation: statusTextFade 1s;
}

.status-hero-icon {
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  user-select: none;
}

.status-hero-name {
  font-size: 1.15rem;
}

@media (min-width: $breakpoint-xs) {
  .status-hero-name {
    font-size: 1.3rem;
  }

  .status-bar {
    min-height: 10.5rem;
  }
  .status-bar-no-icons {
    min-height: 8.5rem;
  }
}

@media (min-width: $breakpoint-sm) {
  .status-hero-name {
    font-size: 1.4rem;
  }

  .status-bar {
    min-height: 7.5rem;
  }
  .status-bar-no-icons {
    min-height: 4.5rem;
  }
}

@media (min-width: $breakpoint-md) {
  .status-hero-name {
    font-size: 1.5rem;
  }
}

@-webkit-keyframes statusTextFade {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-moz-keyframes statusTextFade {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes statusTextFade {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
