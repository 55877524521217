@import "styles/variables.scss";

.lobby-view-disconnect {
  margin-left: auto;
  margin-bottom: 0.6rem;
}

.lobby-view-panels {
  gap: 1rem;
  flex-direction: column;
}

.lobby-view-inner-panels {
  gap: 1rem;
  flex: auto;
}

@media (min-width: $breakpoint-md) {
  .lobby-view-panels {
    flex-direction: row;
  }
}
