body {
  margin: 0;
  font-family: Roboto, Arial !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: Roboto, Arial !important;
}

// Globally set for everything in the page body
// Using helmet to be able to switch page body class names based on theme toggle
.helmet-body-light {
  background-color: $page-background-color-light;
  h1 {
    color: $text-theme-header-light;
  }
  h2 {
    color: $text-theme-header-light;
  }
  h3 {
    color: $text-theme-header-light;
  }
  h4 {
    color: $text-theme-light;
  }
  h5 {
    color: $text-theme-light;
  }
  p {
    color: $text-theme-light;
  }
  height: 100vh;
}
.helmet-body-dark {
  background-color: $page-background-color-dark;
  h1 {
    color: $text-theme-header-dark;
  }
  h2 {
    color: $text-theme-header-dark;
  }
  h3 {
    color: $text-theme-header-dark;
  }
  h4 {
    color: $text-theme-dark;
  }
  h5 {
    color: $text-theme-dark;
  }
  p {
    color: $text-theme-dark;
  }
  height: 100vh;
}
.helmet-body-change-transition {
  -webkit-transition: background-color 0.3s;
  -moz-transition: background-color 0.3s;
  transition: background-color 0.3s;
}

.content-holder-light {
  background-color: $card-bg-light;
  border-radius: 0.3rem;
  transition: background-color 0.3s;
}
.content-holder-dark {
  background-color: $card-bg-dark;
  border-radius: 0.3rem;
  transition: background-color 0.3s;
}

.fast-fade-reveal {
  -webkit-animation: fastFadeReveal 0.1s linear;
  -moz-animation: fastFadeReveal 0.1s linear;
  animation: fastFadeReveal 0.1s linear;
}

@-webkit-keyframes fastFadeReveal {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-moz-keyframes fastFadeReveal {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fastFadeReveal {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.slide-down-appear {
  -webkit-animation: slideDownAppear 0.2s;
  -moz-animation: slideDownAppear 0.2s;
  animation: slideDownAppear 0.2s;
}
@-webkit-keyframes slideDownAppear {
  from {
    margin-top: -15px;
  }
}
@-moz-keyframes slideDownAppear {
  from {
    margin-top: -15px;
  }
}
@keyframes slideDownAppear {
  from {
    margin-top: -15px;
  }
}
