@import "styles/variables.scss";

.hero-icon-wrapper {
  margin: 1px;
  width: 100%;
  max-height: 100px;
  max-width: 100px;
  height: intrinsic; // Forces images to fill height on Safari THANKS TIM
  border-radius: 7px;
}

.hero-icon-wrapper-valid-light {
  background-color: $primary-color-light;
}
.hero-icon-wrapper-invalid-light {
  background-color: $secondary-color-light;
}

.hero-icon-wrapper-valid-dark {
  background-color: $primary-color-dark;
}
.hero-icon-wrapper-invalid-dark {
  background-color: $secondary-color-dark;
}

.hero-icon-wrapper-dev {
  background-color: green;
}

.hero-icon {
  padding: 0;
  width: 100%;
  max-height: 100px;
  max-width: 100px;
  height: intrinsic;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  user-select: none;
}

.hero-icon-invalid {
  opacity: 0.5;
}

.hero-icon-rotate {
  animation: rotation 1s infinite linear;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}
