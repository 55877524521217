$page-background-color-light: #ffffff;
$page-background-color-dark: #2a2a2e;

$primary-color-light: #303f9f;
$secondary-color-light: #6c757d;
$danger-color-light: #c2185b;
$header-color-light: #f5f5f5;
$text-theme-light: #2f3c4a;
$text-theme-header-light: #000000;
$card-bg-light: #e9e9e9;

$primary-color-dark: #005a64;
$secondary-color-dark: #484848;
$danger-color-dark: #a00037;
$header-color-dark: #1b1e1f;
$text-theme-dark: #d1cdc7;
$text-theme-header-dark: #eeeeee;
$card-bg-dark: #343a40;

$overlay-tooltip-color: #757575;

$breakpoint-xs: 476px;
$breakpoint-sm: 576px;
$breakpoint-md: 768px;
$breakpoint-lg: 992px;
$breakpoint-xl: 1200px;

$enable-responsive-font-sizes: true;
