@import "styles/variables.scss";

.navbar {
  -webkit-transition: background-color 0.3s;
  -moz-transition: background-color 0.3s;
  transition: background-color 0.3s;
  z-index: 1000; //Place header above other page components, but below bootstrap modal (1050)
}

.navbar-brand {
  margin-top: -1px;
}

.navbar-brand > img {
  margin-top: -4px;
}

.header-secondary {
  color: #9e9689 !important;
}

.tooltip [class$="inner"] {
  background-color: $overlay-tooltip-color;
  border: 1px solid $overlay-tooltip-color;
}

.bs-tooltip-bottom .arrow::before,
.bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
  border-bottom-color: $overlay-tooltip-color;
}

.header-btn {
  box-shadow: none !important;
}

.header-switch-wrapper {
  margin-top: 1px;
}

// Set background colors for when switch is toggled
// Need to be here as the switch API only takes hex strings
.header-switch-on > div.react-switch-bg {
  background: $secondary-color-dark !important;
}
.header-switch-off > div.react-switch-bg {
  background: $secondary-color-light !important;
}

.navbar-toggle {
  border: 0px;
}

.navbar-toggle-dark {
  background-color: #6c757d;
}
