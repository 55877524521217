@import "styles/variables.scss";

.conn-player-name {
  flex: 1;
  min-width: 0;
  h4 {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.connected-players {
  min-width: 200px; // Allows trailing ellipses for long names
  max-width: default;
}

@media (min-width: $breakpoint-md) {
  .connected-players {
    max-width: 28vw;
  }
}

@media (min-width: $breakpoint-xl) {
  .connected-players {
    max-width: 320px;
  }
}
